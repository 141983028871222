import React from 'react';
import '../Overview/Overview.css';
import './Reporting.css';
import { Menu } from '../Menu.js';
import { TopNav } from '../TopNav.js';
import Chart from "react-apexcharts";
import { BarChart } from "../Overview/BarChart";
import { PercentageBar } from "../Overview/PercentageBar";
import employees from '../../assets/images/card-person.png';
import money from '../../assets/images/card-money-2.png';
import time from '../../assets/images/card-time.png';
import age from '../../assets/images/card-age.png';
import { fetchAuthUpload } from '../../fetch-auth';
import { fetchAuth } from '../../fetch-auth';
import down from '../../assets/images/down.png';
import up from '../../assets/images/up.png';
import lang from '../Language/language.js';
import cloud from '../../assets/images/cloud.png';
import uploadIcon from '../../assets/images/upload-icon.png';
import TextareaAutosize from 'react-textarea-autosize'; //For textareas to autosize
import { Information } from '../Information/Information.js';
import female from '../../assets/images/femalegender.png';
import male from '../../assets/images/malegender.png';
import genderWomanBig from '../../assets/images/genderWomanBig.png';
import genderManBig from '../../assets/images/genderManBig.png';

export class Reporting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            missingEqualGroups: false,
            maleDominated: [],
            womenDominated: [],
            notDominated: [],
            noData: false,
            nrOfEmp: 0,
            avgSalary: "",
            avgSalaryWomen: "",
            avgSalaryMen: "",
            avgAge: 0,
            avgAgeWomen: 0,
            avgAgeMen: 0,
            expAge: 0,
            expAgeWomen: 0,
            expAgeMen: 0,
            //For EU data
            payGapEqualentGroups: [{ group: 1, payGap: 15 }, { group: 2, payGap: 12 }, { group: 3, payGap: 35 }, { group: 4, payGap: 7 }, { group: 5, payGap: 3 }, { group: 6, payGap: 5 }],
            showWorkCategories: false,
            showWhat: "extern", //original, all, intern, extern. Change this to "all" when ready to deploy this function, if not ready, change to "original"
            salarySetting: "totalSalary", // Change to "salary" or "totalSalary" depending on what shouöd be used in the calculations
            avgSalDiffPercent: 0,
            medianSalDiffPercent: 0,
            avgBenefitDiffPercent: 0,
            medianBenefitDiffPercent: 0,
            menWithBonusPercent: 0,
            womenWithBonusPercent: 0,
            step: "two",
            showWhyInfo: false,
            showUpload: false,
            // For import sending
            uploadStatus: 'noUpload',
            errorMessage: '',
            allErrors: [],
            selectYear: 2024,
            allYears: [],
            // For imported (holding recieved imported data)
            allData: [],
            eqGroupSetting: "",
            summaries: [],
            persData: [],
            reportingGroups: [],
            // For calculations
            avgSalaryWomenOfMen: "",
            avgSalaryWomenOfMenMedian: "",
            avgSalaryWomenOfMenExtra: "",
            avgSalaryWomenOfMenMedianExtra: "",
            avgSalaryWomenOfMenHourly: "",
            avgSalaryWomenOfMenMedianHourly: "",
            avgSalaryWomenOfMenHourlyExtra: "",
            avgSalaryWomenOfMenMedianHourlyExtra: "",
            menWithExtraPercent: "",
            womenWithExtraPercent: "",
            quartiles: false,
            equivalentGroupInfo: [],
            // Controll if data is imported
            dataisImported: false, // false or a date

            //For donut chart
            series: [50, 50],
            options: {
                labels: ['Män', 'Kvinnor'],
                colors: ['#54c7b6', '#55c2ca'],
                plotOptions: {
                    pie: {
                        donut: {
                            size: '52%',
                            /* labels: {
                                 show: true,
                             }*/
                        }
                    }
                }
            }
        }
    }

    doCalculations = (year) => {
        let employees = this.state.allData.filter(person => person.year == year) // Only calculate for the selected year
        let eqGroupSetting = this.state.eqGroupSetting
        let summaries = this.state.summaries

        //Calculate
        //1. Nr of Employees
        const nrOfEmp = employees.length
        //2. Genders
        const womenList = employees.filter(person => person.gender === "woman")
        const menList = employees.filter(person => person.gender === "man")
        const nrOfWomen = womenList.length
        const nrOfMen = menList.length
        //const womenPercent = Math.round((nrOfWomen / (nrOfWomen + nrOfMen)) * 100)
        //3. Average salary
        const totalSumWomen = womenList.reduce((acc, num) => acc + (num.annualSalary + num.annualExtra), 0)
        const avgSalaryWomen = Math.round(totalSumWomen / nrOfWomen)
        const totalSumMen = menList.reduce((acc, num) => acc + (num.annualSalary + num.annualExtra), 0)
        const avgSalaryMen = Math.round(totalSumMen / nrOfMen)
        const avgSalaryWomenOfMen = Math.round((avgSalaryWomen / avgSalaryMen) * 100)
        //4. Median salary
        // Function to calculate the median
        const median = (salaries) => {
            const sorted = [...salaries].sort((a, b) => a - b);
            const mid = Math.floor(sorted.length / 2);

            if (sorted.length % 2 === 0) {
                return (sorted[mid - 1] + sorted[mid]) / 2;
            } else {
                return sorted[mid];
            }
        };
        // Function to calculate total salary (annualSalary + annualExtra) for each person
        const calculateTotalSalaries = (salaries) => {
            return salaries.map(salary => salary.annualSalary + salary.annualExtra);
        };
        // Calculate total salaries for men and women
        const totalMenSalaries = calculateTotalSalaries(menList);
        const totalWomenSalaries = calculateTotalSalaries(womenList);
        // Calculate the median total salary for men and women
        const medianMenSalary = median(totalMenSalaries);
        const medianWomenSalary = median(totalWomenSalaries);
        const avgSalaryWomenOfMenMedian = Math.round((medianWomenSalary / medianMenSalary) * 100)

        // 5. Average extra
        const totalSumWomenExtra = womenList.reduce((acc, num) => acc + num.annualExtra, 0)
        const avgSalaryWomenExtra = Math.round(totalSumWomenExtra / nrOfWomen)
        const totalSumMenExtra = menList.reduce((acc, num) => acc + num.annualExtra, 0)
        const avgSalaryMenExtra = Math.round(totalSumMenExtra / nrOfMen)
        const avgSalaryWomenOfMenExtra = Math.round((avgSalaryWomenExtra / avgSalaryMenExtra) * 100)

        // 6. Median extra
        const calculateTotalSalariesExtra = (salaries) => { return salaries.map(salary => salary.annualExtra); };
        const totalMenSalariesExtra = calculateTotalSalariesExtra(menList);
        const totalWomenSalariesExtra = calculateTotalSalariesExtra(womenList);
        const medianMenSalaryExtra = median(totalMenSalariesExtra);
        const medianWomenSalaryExtra = median(totalWomenSalariesExtra);
        const avgSalaryWomenOfMenMedianExtra = Math.round((medianWomenSalaryExtra / medianMenSalaryExtra) * 100)

        // 7. Do above for hourly salary 
        // Add the hourlySalary
        menList.forEach(salary => {
            const totalSalary = salary.annualSalary + (salary.annualExtra || 0);// Ensure annualExtra is treated as 0 if it's null or undefined
            const hourlySalary = totalSalary / salary.workedHours;
            salary.hourlySalary = hourlySalary;  // Add the hourlySalary property to each object
            const hourlySalaryExtra = (salary.annualExtra || 0) / salary.workedHours;
            salary.hourlySalaryExtra = hourlySalaryExtra;

        });
        womenList.forEach(salary => {
            const totalSalary = salary.annualSalary + (salary.annualExtra || 0);// Ensure annualExtra is treated as 0 if it's null or undefined
            const hourlySalary = totalSalary / salary.workedHours;
            salary.hourlySalary = hourlySalary;  // Add the hourlySalary property to each object
            const hourlySalaryExtra = (salary.annualExtra || 0) / salary.workedHours;
            salary.hourlySalaryExtra = hourlySalaryExtra;
        });
        // 7.1 Average hourly Salary
        const totalSumWomenHourly = womenList.reduce((acc, num) => acc + num.hourlySalary, 0)
        const avgSalaryWomenHourly = Math.round(totalSumWomenHourly / nrOfWomen)
        const totalSumMenHourly = menList.reduce((acc, num) => acc + num.hourlySalary, 0)
        const avgSalaryMenHourly = Math.round(totalSumMenHourly / nrOfMen)
        const avgSalaryWomenOfMenHourly = Math.round((avgSalaryWomenHourly / avgSalaryMenHourly) * 100)
        // 7.2 Median hourly salary
        const calculateTotalSalariesHourly = (salaries) => { return salaries.map(salary => salary.hourlySalary); };
        const totalMenSalariesHourly = calculateTotalSalariesHourly(menList);
        const totalWomenSalariesHourly = calculateTotalSalariesHourly(womenList);
        const medianMenSalaryHourly = median(totalMenSalariesHourly);
        const medianWomenSalaryHourly = median(totalWomenSalariesHourly);
        const avgSalaryWomenOfMenMedianHourly = Math.round((medianWomenSalaryHourly / medianMenSalaryHourly) * 100)
        // 7.3 Average hourly extra
        const totalSumWomenHourlyExtra = womenList.reduce((acc, num) => acc + num.hourlySalaryExtra, 0)
        const avgSalaryWomenHourlyExtra = Math.round(totalSumWomenHourlyExtra / nrOfWomen)
        const totalSumMenHourlyExtra = menList.reduce((acc, num) => acc + num.hourlySalaryExtra, 0)
        const avgSalaryMenHourlyExtra = Math.round(totalSumMenHourlyExtra / nrOfMen)
        const avgSalaryWomenOfMenHourlyExtra = Math.round((avgSalaryWomenHourlyExtra / avgSalaryMenHourlyExtra) * 100)
        // 7.4 Median hourly extra
        const calculateTotalSalariesHourlyExtra = (salaries) => { return salaries.map(salary => salary.hourlySalaryExtra); };
        const totalMenSalariesHourlyExtra = calculateTotalSalariesHourlyExtra(menList);
        const totalWomenSalariesHourlyExtra = calculateTotalSalariesHourlyExtra(womenList);
        const medianMenSalaryHourlyExtra = median(totalMenSalariesHourlyExtra);
        const medianWomenSalaryHourlyExtra = median(totalWomenSalariesHourlyExtra);
        const avgSalaryWomenOfMenMedianHourlyExtra = Math.round((medianWomenSalaryHourlyExtra / medianMenSalaryHourlyExtra) * 100)

        // 8. How many of each gender that has extra
        const menWithExtra = menList.filter(salary => salary.annualExtra > 0);
        const menWithExtraPercent = Math.round((menWithExtra.length / menList.length) * 100)
        const womenWithExtra = womenList.filter(salary => salary.annualExtra > 0);
        const womenWithExtraPercent = Math.round((womenWithExtra.length / womenList.length) * 100)

        // 9. Calculate quartiles
        const salaries = employees.map(obj => (obj.annualSalary + obj.annualExtra));
        const salariesSorted = salaries.sort((a, b) => a - b);
        const salariesLength = salariesSorted.length
        function calculateQuartiles(percentile) {
            // Get percentile 25 or other selected percentile/quartile
            const index = percentile * (salariesLength - 1);
            const lowerIndex = Math.floor(index); // This finds the index for the element just before the calculated percentile
            const upperIndex = Math.ceil(index); // This finds the index for the element just after the calculated percentile
            const fraction = index - lowerIndex;

            if (lowerIndex === upperIndex) { // If the index is an integer (whole number), use that
                return salariesSorted[index];
            } else { // If not, use interpolation between the closest two values
                const lowerValue = salariesSorted[lowerIndex];
                const upperValue = salariesSorted[upperIndex];
                console.log("lowerValue: " + lowerValue)
                console.log("upperValue " + upperValue)
                // Return the 25 percentile
                return lowerValue + (upperValue - lowerValue) * (fraction);
            }
        }
        let percentile25 = calculateQuartiles(0.25)
        let percentile50 = calculateQuartiles(0.5)
        let percentile75 = calculateQuartiles(0.75)
       /* console.log("salariesSorted")
        console.log(salariesSorted)
        console.log("percentile25")
        console.log(percentile25)
        console.log("percentile50")
        console.log(percentile50)
        console.log("percentile75")
        console.log(percentile75)*/
        // Push employees into correct quartile
        const quartile1 = [];
        const quartile2 = [];
        const quartile3 = [];
        const quartile4 = [];
        // Group objects based on their salary
        for (const obj of employees) {
            if ((obj.annualSalary + obj.annualExtra) <= percentile25) {
                quartile1.push(obj.gender);
            } else if ((obj.annualSalary + obj.annualExtra) <= percentile50) {
                quartile2.push(obj.gender);
            } else if ((obj.annualSalary + obj.annualExtra) <= percentile75) {
                quartile3.push(obj.gender);
            } else {
                quartile4.push(obj.gender);
            }
        }
        let quartiles = {
            quartile1: {
                "menP": quartile1.length > 0 ? Math.round((quartile1.filter(gender => gender === "man").length / quartile1.length) * 100) : 0,
                "womP": quartile1.length > 0 ? Math.round((quartile1.filter(gender => gender === "woman").length / quartile1.length) * 100) : 0,
                "menNr": quartile1.filter(gender => gender === "man").length,
                "womNr:": quartile1.filter(gender => gender === "woman").length
            },
            quartile2: {
                "menP": quartile2.length > 0 ? Math.round((quartile2.filter(gender => gender === "man").length / quartile2.length) * 100) : 0,
                "womP": quartile2.length > 0 ? Math.round((quartile2.filter(gender => gender === "woman").length / quartile2.length) * 100) : 0,
                "menNr": quartile2.filter(gender => gender === "man").length,
                "womNr:": quartile2.filter(gender => gender === "woman").length
            },
            quartile3: {
                "menP": quartile3.length > 0 ? Math.round((quartile3.filter(gender => gender === "man").length / quartile3.length) * 100) : 0,
                "womP": quartile3.length > 0 ? Math.round((quartile3.filter(gender => gender === "woman").length / quartile3.length) * 100) : 0,
                "menNr": quartile3.filter(gender => gender === "man").length,
                "womNr:": quartile3.filter(gender => gender === "woman").length
            },
            quartile4: {
                "menP": quartile4.length > 0 ? Math.round((quartile4.filter(gender => gender === "man").length / quartile4.length) * 100) : 0,
                "womP": quartile4.length > 0 ? Math.round((quartile4.filter(gender => gender === "woman").length / quartile4.length) * 100) : 0,
                "menNr": quartile4.filter(gender => gender === "man").length,
                "womNr:": quartile4.filter(gender => gender === "woman").length
            }
        }

        // 11. Set state
        this.setState({
            avgSalaryWomenOfMen: avgSalaryWomenOfMen,
            avgSalaryWomenOfMenMedian: avgSalaryWomenOfMenMedian,
            avgSalaryWomenOfMenExtra: avgSalaryWomenOfMenExtra,
            avgSalaryWomenOfMenMedianExtra: avgSalaryWomenOfMenMedianExtra,
            avgSalaryWomenOfMenHourly: avgSalaryWomenOfMenHourly,
            avgSalaryWomenOfMenMedianHourly: avgSalaryWomenOfMenMedianHourly,
            avgSalaryWomenOfMenHourlyExtra: avgSalaryWomenOfMenHourlyExtra,
            avgSalaryWomenOfMenMedianHourlyExtra: avgSalaryWomenOfMenMedianHourlyExtra,
            menWithExtraPercent: menWithExtraPercent,
            womenWithExtraPercent: womenWithExtraPercent,
            quartiles: quartiles,
        })

        // 12. Do calculations for work categories / equivalentgroups
        let persData = this.state.persData // This is the latest uploaded data 
        let reportingGroups = this.state.reportingGroups // past years data (summary)

        // First, check what year. If we do for the period 2024-01-01 - 2024-12-31 and persData was uploaded during 2024, use persData, otherwise, check if reportingGroups has the correct year and use that instead. If none has the correct year, leave it empty.
        console.log(persData)
        if (persData.length > 0 && persData[0].dateAdded.slice(0, 4) == year) {// If true, use persData for calculating the equalentGroup info, otherwise use reportingGroup
            let eqGroupSetting = this.state.eqGroupSetting // "original", "edited" or "extLevel"
            let whatGroup = "equalentGroup"
            if (eqGroupSetting === "edited") { whatGroup = "editedEqualentGroup" } else if (eqGroupSetting === "extLevel") { whatGroup = "extEqualentGroup" }
            // Function to calculate average salary
            const calculateAverageSalary = (salaries) => {
                const total = salaries.reduce((sum, salary) => sum + salary, 0);
                return total / salaries.length;
            };

            // Group employees by extEqualentGroup
            const groups = this.state.persData.reduce((acc, emp) => {
                if (!acc[emp[whatGroup]]) {
                    acc[emp[whatGroup]] = { men: [], women: [] };
                }
                if (emp.gender === "man") {
                    acc[emp[whatGroup]].men.push(emp.totalSalary);
                } else if (emp.gender === "woman") {
                    acc[emp[whatGroup]].women.push(emp.totalSalary);
                }
                return acc;
            }, {});

            // Calculate pay gap for each group
            const payGaps = Object.keys(groups).map(group => {
                const { men, women } = groups[group];
                const averageMenSalary = men.length > 0 ? calculateAverageSalary(men) : 0;
                const averageWomenSalary = women.length > 0 ? calculateAverageSalary(women) : 0;
                const payGap = averageMenSalary > 0 ? ((averageWomenSalary / averageMenSalary) * 100).toFixed(1) : 0;
                return {
                    group: group,
                    averageMenSalary,
                    averageWomenSalary,
                    payGap
                };
            });
            console.log(payGaps)

            // Add summaries to payGaps
            let summaries = this.state.summaries.filter(person => person.dateAdded.slice(0, 4) == year) // Get only summaries for the selected year
            console.log(summaries)
            // Merge summaries into payGaps based on matching extEqualentGroup and groupE
            payGaps.forEach(payGap => {
                const matchingSummary = summaries.find(summary => summary.groupE.toString() === payGap.group);
                if (matchingSummary) {
                    // Merge properties from the matching summary into the payGap object
                    Object.assign(payGap, matchingSummary);
                }
            });

            this.setState({
                equivalentGroupInfo: payGaps
            })

        } else { // If not true, use reportingGroup


            // DO IT HERE !!! <------------------------- LOOK !!
            this.setState({
                equivalentGroupInfo: []
            })

        }
    }

    getDataNew = async (afterImport) => {
        let language = localStorage.getItem('language') ?? 'sv';
        if (language !== "sv") {
            this.setState(prevState => ({
                options: {
                    ...prevState.options,
                    labels: [lang[language].men, lang[language].women],
                }
            }));
        }
        const selectedYear = localStorage.getItem("year")
        const currentYearSelected = localStorage.getItem("currentYearSelected") //This is "Yes" or "No"
        let useYear = "Now"
        if (selectedYear !== null && currentYearSelected === "No") {
            useYear = Number(selectedYear)
        }
        if (useYear === "Now") {
            useYear = new Date().getFullYear()
        }
        let currentYear = new Date().getFullYear();
        let fiveYears = []
        for (let i = 0; i < 5; i++) {
            fiveYears.push(currentYear - i);
        }
        if (afterImport === "afterImport") { useYear = this.state.selectYear }
        this.setState({ selectYear: useYear, allYears: fiveYears })
        try {
            const response = await fetchAuth(`/api/loadOverviewNew`, 'POST');
            let data = await response.json();
            console.log(data)
            let imported = data[0][0]
            let eqGroupSetting = data[1][0][0]
            let summaries = data[2][0]
            let whatEvaluation = "original"
            if (eqGroupSetting.whatEvaluation !== undefined) { whatEvaluation = eqGroupSetting.whatEvaluation } // original, edited, extLevel 
            console.log(whatEvaluation)
            let persData = data[3][0]
            let reportingGroups = data[4][0]

            if (data[0].length < 1 || data[0] === undefined) {
                this.setState({ noData: true })
                return;
            } else if (imported.length < 1) {
                this.setState({ noData: true })
                return;
            } else {
                this.setState({
                    allData: imported,
                    eqGroupSetting: whatEvaluation,
                    summaries: summaries
                })
                this.setState(
                    {
                        allData: imported,
                        eqGroupSetting: whatEvaluation,
                        summaries: summaries,
                        persData: persData,
                        reportingGroups: reportingGroups
                    },
                    () => {
                        this.doCalculations(useYear);
                        this.selectStepOne("firstTime");
                    });
                return;
            }
        } catch (err) {

        }
    }

    componentDidMount() {
        this.getDataNew();

        /* const getData = async () => {
             try {
                 const response = await fetchAuth(`/api/loadOverview`, 'POST');
                 let data = await response.json();
                 let employees = []
                 if (data[0].length < 1 || data[0] === undefined) {
                     this.setState({ noData: true })
                     return;
                 } else {
                     let calcSal = this.state.salarySetting //Change to "totalSalary" (with addOn) or "salary" (without addOn) // Note, this changes everything except "medellon i kronor intill medarbetare"
 
                     employees = data[0][0]
                     let equalentGroupSetting = data[1][0][0].whatEvaluation
                     if (equalentGroupSetting === undefined || equalentGroupSetting === null || equalentGroupSetting === "off") {
                         equalentGroupSetting = "original"
                     }
 
                     //Calculate for statistics
                     //1. Nr of Employers
                     const nrOfEmp = employees.length
                     //2. Genders
                     const womenList = employees.filter(person => person.gender === "woman")
                     const menList = employees.filter(person => person.gender === "man")
                     const nrOfWomen = womenList.length
                     const nrOfMen = menList.length
                     //const womenPercent = Math.round((nrOfWomen / (nrOfWomen + nrOfMen)) * 100)
                     //3. Average salary
                     const salaryTotalSum = employees.reduce((acc, num) => acc + num[calcSal], 0)
                     const avgSalary = Math.round(salaryTotalSum / nrOfEmp)
                     const totalSumWomen = womenList.reduce((acc, num) => acc + num[calcSal], 0)
                     const avgSalaryWomen = Math.round(totalSumWomen / nrOfWomen)
                     const totalSumMen = menList.reduce((acc, num) => acc + num[calcSal], 0)
                     const avgSalaryMen = Math.round(totalSumMen / nrOfMen)
                     //4. Average Age
                     const ageTotalSum = employees.reduce((acc, num) => acc + num.age, 0)
                     const avgAge = Math.round(ageTotalSum / nrOfEmp)
                     const ageTotalSumWomen = womenList.reduce((acc, num) => acc + num.age, 0)
                     const ageAvgWomen = Math.round(ageTotalSumWomen / nrOfWomen)
                     const ageTotalSumMen = menList.reduce((acc, num) => acc + num.age, 0)
                     const ageAvgMen = Math.round(ageTotalSumMen / nrOfMen)
                     //4. Average Experience
                     const expTotalSum = employees.reduce((acc, num) => acc + num.experience, 0)
                     const avgExp = Math.round(expTotalSum / nrOfEmp)
                     const expTotalSumWomen = womenList.reduce((acc, num) => acc + num.experience, 0)
                     const expAvgWomen = Math.round(expTotalSumWomen / nrOfWomen)
                     const expTotalSumMen = menList.reduce((acc, num) => acc + num.experience, 0)
                     const expAvgMen = Math.round(expTotalSumMen / nrOfMen)
 
                     //1-4. Create thousand separator:
                     const avgSalarySeparated = avgSalary.toLocaleString()
                     const avgSalarySeparatedWomen = avgSalaryWomen.toLocaleString()
                     const avgSalarySeparatedMen = avgSalaryMen.toLocaleString()
 
                     //5. Gender dominated groups
                     //5a. divide all groups into array based on equalGroup (groupNameArbVardering)
                     let groups = {};
                     for (let i = 0; i < employees.length; i++) {
                         let groupName = employees[i].groupNameArbVardering;
                         if (!groups[groupName]) {
                             groups[groupName] = [];
                         }
                         groups[groupName].push(employees[i].gender);
                     }
                     let myArray = [];
                     for (let groupName in groups) {
                         myArray.push({ group: groupName, gender: groups[groupName] });
                     }
                     //5b. Count how many genders in each group and add what its dominated by
                     for (let i = 0; i < myArray.length; i++) {
                         let totalEmps = myArray[i].gender.length
                         let nrOfMen = []
                         let nrOfWomen = []
                         for (let a = 0; a < myArray[i].gender.length; a++) {
                             if (myArray[i].gender[a] === "man") nrOfMen.push(myArray[i].gender[a])
                             if (myArray[i].gender[a] === "woman") nrOfWomen.push(myArray[i].gender[a])
                         }
                         if (nrOfMen.length / (nrOfWomen.length + nrOfMen.length) > 0.59) { myArray[i].dominatedBy = "men" }
                         else if (nrOfWomen.length / (nrOfWomen.length + nrOfMen.length) > 0.59) { myArray[i].dominatedBy = "women" }
                         else { myArray[i].dominatedBy = "equal" }
                     }
 
                     //5c. Count how many of each dominated group
                     const maleDominated = myArray.filter(g => g.dominatedBy === "men").length
                     const womenDominated = myArray.filter(g => g.dominatedBy === "women").length
                     const noDominiation = myArray.filter(g => g.dominatedBy === "equal").length
                     const maleDominatedPercent = Math.round((maleDominated / (maleDominated + womenDominated + noDominiation)) * 100)
                     const womenDominatedPercent = Math.round((womenDominated / (maleDominated + womenDominated + noDominiation)) * 100)
                     const noDominiationPercent = Math.round((noDominiation / (maleDominated + womenDominated + noDominiation)) * 100)
 
                     //5d. Check if any group is nulled, then show message about this. It means there is groups that are not set in group Lika
                     const equalNotGrouped = myArray.filter(g => g.group === "null").length
                     if (equalNotGrouped > 0) {
                         this.setState({ missingEqualGroups: true })
                     }
 
                     //When all is calculated, set state
                     this.setState({
                         maleDominated: [maleDominated, maleDominatedPercent],
                         womenDominated: [womenDominated, womenDominatedPercent],
                         notDominated: [noDominiation, noDominiationPercent],
                         nrOfEmp: nrOfEmp,
                         avgSalary: avgSalarySeparated,
                         avgSalaryWomen: avgSalarySeparatedWomen,
                         avgSalaryMen: avgSalarySeparatedMen,
                         avgAge: avgAge,
                         avgAgeWomen: ageAvgWomen,
                         avgAgeMen: ageAvgMen,
                         expAge: avgExp,
                         expAgeWomen: expAvgWomen,
                         expAgeMen: expAvgMen,
                         series: [nrOfMen, nrOfWomen]
                     })
 
                     // Here calculate data for EU directive ---
 
                     if (this.state.showWhat !== "original") {
                         // -- Calculate quartiles --
                         // Extract the totalSalary values from the objects and sort them in ascending order
                         const salaries = employees.map(obj => obj[calcSal]);
                         salaries.sort((a, b) => a - b);
                         // Calculate Q1, Q2 (median), Q3, and Q4
                         const q1 = calculateQuartile(salaries, 0.25);
                         const q2 = calculateQuartile(salaries, 0.5);
                         const q3 = calculateQuartile(salaries, 0.75);
                         // Function to calculate quartiles using linear interpolation
                         function calculateQuartile(sortedArray, quartile) {
                             const position = (sortedArray.length - 1) * quartile;
                             const lowerIndex = Math.floor(position);
                             const upperIndex = Math.ceil(position);
                             const weight = position - lowerIndex;
                             if (lowerIndex === upperIndex) {
                                 return sortedArray[lowerIndex];
                             } else {
                                 return sortedArray[lowerIndex] + weight * (sortedArray[upperIndex] - sortedArray[lowerIndex]);
                             }
                         }
                         // Initialize arrays for each quartile
                         const quartile1 = [];
                         const quartile2 = [];
                         const quartile3 = [];
                         const quartile4 = [];
                         // Group objects based on their salary
                         for (const obj of employees) {
                             if (obj[calcSal] <= q1) {
                                 quartile1.push(obj);
                             } else if (obj[calcSal] <= q2) {
                                 quartile2.push(obj);
                             } else if (obj[calcSal] <= q3) {
                                 quartile3.push(obj);
                             } else {
                                 quartile4.push(obj);
                             }
                         }
                         // Now calculate the gender percentage inside each quartile
                         function calculatePercentage(data) {
                             let menCount = 0;
                             let womenCount = 0;
                             for (const person of data) {
                                 if (person.gender === 'man') {
                                     menCount++;
                                 } else if (person.gender === 'woman') {
                                     womenCount++;
                                 }
                             }
                             const totalPeople = data.length;
                             const menPercentage = Math.round((menCount / totalPeople) * 100);
                             const womenPercentage = Math.round((womenCount / totalPeople) * 100);
                             return ({ menP: menPercentage, womP: womenPercentage })
                         }
                         let quartile1P = calculatePercentage(quartile1)
                         let quartile2P = calculatePercentage(quartile2)
                         let quartile3P = calculatePercentage(quartile3)
                         let quartile4P = calculatePercentage(quartile4)
                         let quartileStats = { quartile1: quartile1P, quartile2: quartile2P, quartile3: quartile3P, quartile4: quartile4P }
                         // --- End of quartiles --
 
                         // Calculate gender difference in median and average salaries 
                         function calculateMedian(data, propertyName) {
                             data.sort((a, b) => a[propertyName] - b[propertyName]);
                             const middle = Math.floor(data.length / 2);
                             return data.length % 2 === 0
                                 ? (data[middle - 1][propertyName] + data[middle][propertyName]) / 2
                                 : data[middle][propertyName];
                         }
 
                         const totalSumWomenEU = womenList.reduce((acc, num) => acc + num[calcSal], 0)
                         const avgSalaryWomenEU = Math.round(totalSumWomenEU / nrOfWomen)
                         const totalSumMenEU = menList.reduce((acc, num) => acc + num[calcSal], 0)
                         const avgSalaryMenEU = Math.round(totalSumMenEU / nrOfMen)
 
                         let avgSalaryDifferencePercent = Math.round(((avgSalaryMenEU - avgSalaryWomenEU) / avgSalaryMenEU) * 100)
                         let medianSalaryMen = calculateMedian(menList, [calcSal]);
                         let medianSalaryWomen = calculateMedian(womenList, [calcSal]);
                         let medianSalaryDifferencePercent = Math.round(((medianSalaryMen - medianSalaryWomen) / medianSalaryMen) * 100)
 
                         //Calculate gender difference in addOn and benefits
                         const totalSumMenBenefit = menList.reduce((acc, num) => acc + num.addOn, 0)
                         const avgBenefitMen = Math.round(totalSumMenBenefit / nrOfMen)
                         const totalSumWomenBenefit = womenList.reduce((acc, num) => acc + num.addOn, 0)
                         const avgBenefitWomen = Math.round(totalSumWomenBenefit / nrOfWomen)
                         let avgBenefitDifferencePercent = Math.round(((avgBenefitMen - avgBenefitWomen) / avgBenefitMen) * 100)
                         let medianBenefitMen = calculateMedian(menList, "addOn");
                         let medianBenefitWomen = calculateMedian(womenList, "addOn");
                         let medianBenefitDifferencePercent = Math.round(((medianBenefitMen - medianBenefitWomen) / medianBenefitMen) * 100)
                         if (isNaN(avgBenefitDifferencePercent) || avgBenefitDifferencePercent !== avgBenefitDifferencePercent) { avgBenefitDifferencePercent = 0 }
                         if (isNaN(medianBenefitDifferencePercent) || medianBenefitDifferencePercent !== medianBenefitDifferencePercent) { medianBenefitDifferencePercent = 0 }
 
                         //Nr of gender that bonus and addOn
                         let menWithBenefits = 0
                         let menWithoutBenifits = 0
                         let womenWithBenefits = 0
                         let womenWithoutBenifits = 0
                         for (const obj of menList) {
                             if (obj.hasOwnProperty("addOn")) {
                                 if (obj.addOn === 0 || obj.addOn === null) {
                                     menWithoutBenifits++;
                                 } else {
                                     menWithBenefits++;
                                 }
                             }
                         }
                         for (const obj of womenList) {
                             if (obj.hasOwnProperty("addOn")) {
                                 if (obj.addOn === 0 || obj.addOn === null) {
                                     womenWithoutBenifits++;
                                 } else {
                                     womenWithBenefits++;
                                 }
                             }
                         }
                         let menWithBonusPercent = Math.round((menWithBenefits / (menWithoutBenifits + menWithBenefits)) * 100)
                         let womenWithBonusPercent = Math.round((womenWithBenefits / (womenWithoutBenifits + womenWithBenefits)) * 100)
 
                         // Calculate salary differences for each work category
                         let equalentGroup = "equalentGroup"
                         if (equalentGroupSetting === "edited") { equalentGroup = "editedEqualentGroup" }
                         if (equalentGroupSetting === "extLevel") { equalentGroup = "extEqualentGroup" }
                         // Calculate median and average salaries for men and women in each "equalentGroup"
                         const groupedData = employees.reduce((result, obj) => {
                             const groupKey = obj[equalentGroup]
                             if (!result[groupKey]) {
                                 result[groupKey] = {
                                     equalentGroup: groupKey,
                                     menSalaries: [],
                                     womenSalaries: [],
                                 };
                             }
 
                             if (obj.gender === 'man') {
                                 result[groupKey].menSalaries.push(obj[calcSal]);
                             } else if (obj.gender === 'woman') {
                                 result[groupKey].womenSalaries.push(obj[calcSal]);
                             }
 
                             return result;
                         }, {});
                         // Calculate median salaries and other statistics
                         const resultArray = Object.values(groupedData).map((group) => {
                             const menMedian = calculateMedianG(group.menSalaries);
                             const womenMedian = calculateMedianG(group.womenSalaries);
                             const menAverage = calculateAverage(group.menSalaries);
                             const womenAverage = calculateAverage(group.womenSalaries);
                             const medianDiff = calculatePercentageDifference(menMedian, womenMedian);
                             const averageDiff = calculatePercentageDifference(menAverage, womenAverage);
                             return {
                                 equalentGroup: group.equalentGroup,
                                 menMedian,
                                 womenMedian,
                                 menAverage,
                                 womenAverage,
                                 medianDiff,
                                 averageDiff,
                             };
                         });
                         // Function to calculate the median of an array
                         function calculateMedianG(arr) {
                             if (arr.length === 0) return 0;
                             const sorted = [...arr].sort((a, b) => a - b);
                             const middle = Math.floor(sorted.length / 2);
                             if (sorted.length % 2 === 0) {
                                 const lower = sorted[middle - 1];
                                 const upper = sorted[middle];
                                 return (lower + upper) / 2;
                             } else {
                                 return sorted[middle];
                             }
                         }
                         // Function to calculate the average of an array
                         function calculateAverage(arr) {
                             if (arr.length === 0) return 0;
 
                             const total = arr.reduce((sum, value) => sum + value, 0);
                             return total / arr.length;
                         }
                         // Function to calculate the percentage difference between two values
                         function calculatePercentageDifference(value1, value2) {
                             if (value1 === 0) return 0;
                             // return Math.round((((value1 - value2) / value1) * 100));
                             return (((value1 - value2) / value1) * 100).toFixed(1)
                         }
                         // Save to state
                         this.setState({
                             quartiles: quartileStats,
                             avgSalDiffPercent: avgSalaryDifferencePercent,
                             medianSalDiffPercent: medianSalaryDifferencePercent,
                             avgBenefitDiffPercent: avgBenefitDifferencePercent,
                             medianBenefitDiffPercent: medianBenefitDifferencePercent,
                             menWithBonusPercent: menWithBonusPercent,
                             womenWithBonusPercent: womenWithBonusPercent,
                             payGapEqualentGroups: resultArray
                         })
                         return;
                     }
                 }
             } catch (err) {
                 //   console.log(err)
                 return;
             }
         }
         getData();*/
    }

    toggleWorkCategories = () => { this.state.showWorkCategories ? this.setState({ showWorkCategories: false }) : this.setState({ showWorkCategories: true }) }
    showWhat = (event) => { let showWhat = event.target.value; showWhat === "extern" ? this.setState({ showWhat: showWhat, showWorkCategories: true }) : this.setState({ showWhat: showWhat, showWorkCategories: false }) }
    selectStepOne = (firstTimeLoaded) => {
        // Check if data is imported for the selected year
        let selectYear = this.state.selectYear
        let hasSelectYear = this.state.allData.filter(person => person.year == selectYear)
        if (hasSelectYear.length > 0) {
            // controll when it was uploaded
            let dateWhenUploaded = hasSelectYear[0].dateAdded
            this.setState({ dataisImported: dateWhenUploaded.slice(0, 10), showUpload: false })
        } else {
            this.setState({ dataisImported: false, showUpload: true })
        }
        if (firstTimeLoaded === "firstTime") { this.setState({ step: "two" }) }
        else { this.setState({ step: "one" }) }
    }
    selectStepTwo = () => {
        let selectYear = this.state.selectYear
        let hasSelectYear = this.state.allData.filter(person => person.year == selectYear)
        if (hasSelectYear.length > 0) {
            // controll when it was uploaded
            let dateWhenUploaded = hasSelectYear[0].dateAdded
            this.setState({ showUpload: false })
        }
        this.setState({ step: "two" })
    }
    showWhyInfo = () => { this.state.showWhyInfo ? this.setState({ showWhyInfo: false }) : this.setState({ showWhyInfo: true }) }
    showUpload = () => { this.setState({ showUpload: true }) }

    //send file to server
    fileToServer = event => {
        if (event.target.files[0]) {
            this.upload(event.target.files[0]) //Calls upload function above. Uploads file to server
            this.fileInput.value = '';
        }
    }
    refreshPage = () => { window.location.reload() }


    //Upload function
    upload = (file) => {
        // Get eu setting "on" if used
        let settingEU = localStorage.getItem('settingEU');
        if (settingEU !== "on") { settingEU = "off" }
        //First, check if we import data for the current year or past years.
        /* const selectedYear = localStorage.getItem("year")
         const currentYearSelected = localStorage.getItem("currentYearSelected") //This is "Yes" or "No"
         let useYear = "Now"
         if (selectedYear !== null && currentYearSelected === "No") {
             useYear = Number(selectedYear)
         }*/
        //Then send file and year info
        const data = new FormData()
        data.append('file', file)
        data.append('user', 'hubot')
        data.append('useYear', this.state.selectYear) // Note: We are sending the selected year, not the useYear in settings
        data.append('importDate', this.state.dateToSend) //This can say "standard" or a specific date
        data.append('settingEU', settingEU) // "on" or "off"

        fetchAuthUpload('api/uploadExcelDataReporting', 'POST', data)
            .then(
                response => response.json()
            ).then(
                message => {
                    let language = localStorage.getItem('language') ?? 'sv';
                    console.log(message)
                    if (message === 'ConsultOnSelf') {
                        this.setState({
                            uploadStatus: 'failed',
                            errorMessage: language === "sv" ? 'Ni kan inte utföra lönekartläggning åt denna organisation. Välj ett annat bolag i menyn uppe till höger under konto. ' : lang[language].importNotThisComp
                        })
                    }
                    if (message === 'Invalid fileType') {
                        this.setState({
                            uploadStatus: 'failed',
                            errorMessage: language === "sv" ? 'Felaktigt format. Ladda upp filen i formatet ".xlsx". Använd spara som när ni sparar er excelfil och välj sedan ".xlsx" eller "Excel Workbook".' : lang[language].importWrongFormat
                        })
                    }
                    if (message === 'File is too Large') {
                        this.setState({
                            uploadStatus: 'failed',
                            errorMessage: language === "sv" ? 'För stor fil. Filen kan max vara 20mb.' : lang[language].tooBigFile
                        })
                    }
                    if (message === 'Error occurred') {
                        this.setState({
                            uploadStatus: 'failed',
                        })
                    }
                    if (message[0] === 'No name' ||
                        message[0] === 'Wrong persNr' ||
                        message[0] === 'No salary' ||
                        message[0] === 'No position' ||
                        message[0] === 'No startDate' ||
                        message[0] === 'wrong extrafield' ||
                        message[0] === 'No city' ||
                        message[0] === 'Maxed additional' ||
                        message[0] === 'Wrong parental' ||
                        message[0] === 'Wrong salary raise'
                    ) {
                        this.setState({
                            uploadStatus: 'failed',
                            errorMessage: language === "sv" ? `${message[1].length} personer har fel format eller tomma fält. Se mer info nedan.` : `${message[1].length}${lang[language].importPersonsErr}`,
                            allErrors: message[1]
                        })
                    }
                    if (message === 'Upload success!') {
                        this.setState({
                            uploadStatus: 'success',
                            showUpload: false,
                        })
                        this.getDataNew("afterImport");
                    }
                }
            );
    };

    selectYear = (event) => {
        // Check if data is imported for the selected year
        let selectYear = event.target.value
        let hasSelectYear = this.state.allData.filter(person => person.year == selectYear)
        if (hasSelectYear.length > 0) {
            // controll when it was uploaded
            let dateWhenUploaded = hasSelectYear[0].dateAdded
            this.setState({ dataisImported: dateWhenUploaded.slice(0, 10), showUpload: false })
        } else {
            this.setState({ dataisImported: false, showUpload: true })
        }
        this.setState({ selectYear: event.target.value },
            () => {
                this.doCalculations(selectYear) // Recalculate the data
            });
    }









    render() {
        let errorList = ""
        if (this.state.allErrors.length > 0) {
            errorList = this.state.allErrors.map((item) => {
                return <tr>
                    <td style={{ border: "1px solid", textAlign: "left" }}>{item.name}</td>
                    <td style={{ border: "1px solid", textAlign: "left" }}><ul>{item.errors.map((errors) => { return (<li>- {errors}</li>) })}</ul></td>
                </tr>
            })
        }

        let language = localStorage.getItem('language') ?? 'sv';
        // For dominated groups
        const maleD = Number(this.state.maleDominated[0])
        const WomenD = Number(this.state.womenDominated[0])
        const notD = Number(this.state.notDominated[0])

        const data = [{
            data: [maleD, WomenD, notD]
        }]
        const workCategoriesPayGap = this.state.equivalentGroupInfo.map(group =>
            <div className="overview-list-row">
                <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 5 }}>
                    <div className="overview-list-title">{group.equalentGroup === null ? "Saknar grupp" : "Grupp " + group.group}</div>
                    <div className="overview-list-payGap">{group.payGap} %</div>
                </div>
                <TextareaAutosize className="explainerBoxText" placeholder="Ingen orsak angiven." name="reason" id={group.group} value={group.notable} disabled />
            </div>
        )

        return (
            <>
                <TopNav />
                <Menu />
                <div className="container buggFixContainer">
                    <div style={{ maxWidth: 1250, margin: "auto" }}>
                        {/*<h1 style={{ color: "#333333", paddingBottom: 40, paddingTop: 40 }}>{language === "sv" ? "Rapportering" : lang[language].overviewOrg}</h1>*/}
                        <Information step="rapportera" />
                        <div style={{ marginBottom: 40 }}>
                            <div className="reportingSelectTitle">Välj år</div>
                            <select id="showWhat" className="overview-selection" onChange={this.selectYear} style={{ width: 100, marginRight: 10 }} value={this.state.selectYear}>
                                {this.state.allYears.map(year => (
                                    <option value={year}>{year}</option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <div className="reportingStepContainer" onClick={this.selectStepOne}>
                                <span style={{ marginRight: 0 }} className={this.state.step === "one" ? "num reportingActive" : "num"}>1</span>
                                <div style={{ fontWeight: this.state.step === "one" ? "bold" : "normal" }} className="reportingStepTitle">Importera data</div>
                            </div>
                            <div className="reportingStepContainer" onClick={this.selectStepTwo}>
                                <span style={{ marginRight: 0 }} className={this.state.step === "two" ? "num reportingActive" : "num"}>2</span>
                                <div style={{ fontWeight: this.state.step === "two" ? "bold" : "normal" }} className="reportingStepTitle">Visa statistik</div>
                            </div>
                        </div>
                        <div className="allCommentsContainer" style={{ marginBottom: 140, minHeight: 420 }}>

                            {/* Import container */}
                            <div className={this.state.step === "one" ? "" : "hide"}>
                                <div>
                                    <div className={this.state.showUpload ? "" : "hide"}>
                                        <img className="logoUpload-default" src={uploadIcon} style={{ marginTop: 65 }} />
                                        <label htmlFor="files" className="upload-button upload-button-smallScreen" style={{ display: "block", width: 150, margin: "auto", fontSize: 16, padding: 15 }}>{language === "sv" ? "IMPORTERA" : lang[language].import}</label>
                                    </div>
                                </div>
                                <input name="logo" id="files" style={{ display: "none" }} type="file" onChange={this.fileToServer} ref={input => this.fileInput = input} />


                                <div className={!this.state.showUpload ? "success" : "hide"} style={{ paddingTop: 80 }}>
                                    <div className="importOK" style={{ width: 100, height: 100, lineHeight: "100px", opacity: 0.5, boxShadow: "none" }}>&#10004;</div>
                                    <span className="spanOK">Datan är importerad</span>
                                    <span className="popUpSuccess" style={{ display: "block", marginBottom: 80 }}>Data för {this.state.selectYear} laddades upp {this.state.dataisImported}.
                                    </span>
                                    <div style={{ color: "#35b292", background: "#56cbad", color: "white", padding: 12, borderRadius: 8, fontWeight: 900, cursor: "pointer", width: "fit-content", margin: "auto", marginTop: -40 }} onClick={this.showUpload}>Ersätt med ny import</div>
                                </div>
                            </div>
                            {/* <div className={this.state.uploadStatus === "success" ? "displayImportInfo" : "hideImportInfo"}>
                                <div className="importOK">&#10004;</div>
                                <span className="spanOK">{language === "sv" ? "Data importerad!" : lang[language].dataImported}</span>
                            </div>*/}
                            <div className={this.state.uploadStatus === "failed" ? "displayImportInfo" : "hideImportInfo"}>
                                <div className="importOK" style={{ background: "#cb5656", boxShadow: "none" }}>&#10006;</div>
                                <span className="spanOK">{language === "sv" ? "Ett fel inträffade." : lang[language].importError}</span>
                                <span style={{ display: "block", margin: "auto", maxWidth: 700, marginBottom: 60 }}>{this.state.errorMessage}</span>
                                <span style={{ color: "#35b292", background: "#56cbad", color: "white", padding: 12, borderRadius: 8, fontWeight: 900, cursor: "pointer" }} onClick={this.refreshPage}>{language === "sv" ? "Prova igen" : lang[language].tryAgain}</span>
                                {/*Table with errors */}
                                <table className={this.state.allErrors.length > 0 ? "" : "hide"} style={{ margin: "auto", minWidth: 580, color: "#333333", marginTop: 80 }}>
                                    <tr>
                                        <th style={{ border: "1px solid", width: 290, textAlign: "left" }}>{language === "sv" ? "Namn" : lang[language].name}</th>
                                        <th style={{ border: "1px solid", width: 290, textAlign: "left" }}>{language === "sv" ? "Fält som innehåller fel" : lang[language].containingErrors}</th>
                                    </tr>
                                    {errorList}
                                </table>
                            </div>

                            {/* Nothing imported container */}
                            <div style={{ paddingTop: 40, maxWidth: 600, margin: "auto" }} className={(this.state.showUpload === true && this.state.dataisImported === false && this.state.step === "two") ? "" : "hide"}>
                                <img src={cloud} style={{ marginBottom: 10, marginTop: 40, width: 100, opacity: 0.5 }} />
                                <div style={{ fontWeight: "bold", fontSize: 20 }}>Ingen data importerad</div>
                                <p style={{ fontSize: 16 }}>Klicka på "Importera data" ovan.</p>
                                <div className="showSettingBtn" style={{ width: "fit-content", marginTop: 60 }} onClick={this.showWhyInfo}>Varför en till import?</div>

                                <div className={this.state.showWhyInfo ? "chartPercentInfoBox" : "hide"}>Enligt lag ska rapporteringen innehålla den totala årslönen och timlönen för föregående år. Detta överensstämmer inte med lönekartläggningen där den aktuella månadslönen används.
                                    Detta till följd av krav från EU:s lönetransparensdirektiv i syfte att rapporteringen skall gå att jämföra mellan alla länder.
                                </div>
                            </div>
                            {/* Statistics container */}
                            <div className={(this.state.showUpload === false && this.state.dataisImported !== false && this.state.step === "two") ? "" : "hide"}>

                                {this.state.noData ? <></> : <>
                                    {/* <div className={this.state.showWhat === "original" ? "hide" : ""} style={{ marginTop: 0, marginBottom: 15, paddingTop: 40 }}>
                                        <select id="showWhat" className="overview-selection" onChange={this.showWhat}>
                                            <option value="all">{language === "sv" ? "Visa all statistik" : lang[language].showAllStatistics}</option>
                                            <option value="intern">{language === "sv" ? "Rapport intern - EU direktiv" : lang[language].internalReport}</option>
                                            <option value="extern">{language === "sv" ? "Rapport till myndigheter - EU direktiv" : lang[language].authReport}</option>
                                        </select>
                                    </div> */}

                                    {(this.state.showWhat === "all" || this.state.showWhat === "original") && <>
                                        <div className="card_overview">
                                            <div className="card_padding">
                                                <span>{language === "sv" ? "Medarbetare" : lang[language].employees}</span>
                                                <span className="card_header">{this.state.nrOfEmp}</span>
                                                <img src={employees} className="card_image" style={{ transform: "scaleX(-1)" }} />
                                            </div>
                                        </div>

                                        <div className="card_overview">
                                            <div className="card_padding">
                                                <img src="" />
                                                <span>{language === "sv" ? "Medellön" : lang[language].averageSal}</span>
                                                <span className="card_header">{this.state.avgSalary} {language === "sv" ? "kr" : ""}</span>
                                                <span style={{ display: "block", fontSize: 14, paddingTop: 35 }}>{language === "sv" ? "Män" : lang[language].men}: {this.state.avgSalaryMen}</span>
                                                <span style={{ display: "block", fontSize: 14 }}>{language === "sv" ? "Kvinnor" : lang[language].women}: {this.state.avgSalaryWomen}</span>
                                                <img src={money} className="card_image" style={{ marginRight: -40, marginBottom: -30 }} />
                                            </div>
                                        </div>

                                        <div className="card_overview">
                                            <div className="card_padding">
                                                <img src="" />
                                                <span>{language === "sv" ? "Medelanställningstid" : lang[language].avgTimeInOrg}</span>
                                                <span className="card_header">{this.state.expAge} {language === "sv" ? "år" : lang[language].years}</span>
                                                <span style={{ display: "block", fontSize: 14, paddingTop: 35 }}>{language === "sv" ? "Män" : lang[language].men}: {this.state.expAgeMen}</span>
                                                <span style={{ display: "block", fontSize: 14 }}>{language === "sv" ? "Kvinnor" : lang[language].women}: {this.state.expAgeWomen}</span>
                                                <img src={time} className="card_image" style={{ marginRight: -40, marginBottom: -30 }} />
                                            </div>
                                        </div>

                                        <div className="card_overview">
                                            <div className="card_padding">
                                                <img src="" />
                                                <span>{language === "sv" ? "Medelålder" : lang[language].avgAgeLong}</span>
                                                <span className="card_header">{this.state.avgAge} {language === "sv" ? "år" : lang[language].years}</span>
                                                <span style={{ display: "block", fontSize: 14, paddingTop: 35 }}>{language === "sv" ? "Män" : lang[language].men}: {this.state.avgAgeMen}</span>
                                                <span style={{ display: "block", fontSize: 14 }}>{language === "sv" ? "Kvinnor" : lang[language].women}: {this.state.avgAgeWomen}</span>
                                                <img src={age} className="card_image" style={{ marginRight: -40, marginBottom: -30 }} />
                                            </div>
                                        </div>
                                    </>
                                    }
                                    {/*  <div className="card_overview" style={{ background: "white", color: "#333333", height: 255, width: "45.5%" }}>
                                        <div className="card_padding">
                                            <span style={{ display: "block", margin: "auto", textAlign: "center", paddingBottom: 5 }} className={this.state.showWhat !== "original" ? "euTitle" : ""}>{language === "sv" ? "Könsfördelning" : lang[language].genderDis}</span>
                                            <div style={{ marginTop: 10 }}>
                                                <Chart options={this.state.options} series={this.state.series} type="donut" width="320" />
                                            </div>
                                        </div>
                                    </div>*/}


                                    {this.state.showWhat === "original" &&
                                        <>
                                            <div className="card_overview" style={{ background: "white", color: "#333333", height: 255, width: "45.5%" }}>
                                                <div className="card_padding">
                                                    <span style={{ display: "block", margin: "auto", textAlign: "center", paddingBottom: 5 }}>{language === "sv" ? "Könsdominerande grupper" : lang[language].genderDominant}</span>
                                                    {this.state.missingEqualGroups ? <div className="card_message">{language === "sv" ? "Alla lika arbeten måste grupperas innan denna statistik kan visas. Detta görs i steg 2.1" : lang[language].beforeStatShow}</div>
                                                        :
                                                        <>
                                                            <div style={{ width: "70%", display: "inline-block", marginTop: -20 }}>
                                                                <BarChart chartData={data} />
                                                            </div>
                                                            <div style={{ width: "25%", display: "inline-block", marginTop: 0, verticalAlign: "top" }}>

                                                                <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 20 }}>{this.state.maleDominated[1]} %</span>
                                                                <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 25 }}>{this.state.womenDominated[1]} %</span>
                                                                <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 25 }}>{this.state.notDominated[1]} %</span>
                                                            </div>
                                                        </>}
                                                </div>
                                            </div>
                                        </>
                                    }

                                    {this.state.showWhat !== "original" &&
                                        <>
                                            <div className="card_overview" style={{ background: "white", color: "#333333", height: 270, width: "45.5%", marginTop: 17 }}>
                                                <div className="card_padding">
                                                    <span style={{ marginBottom: 30 }} className="euTitle">{language === "sv" ? "Andel kön per lönekvartil" : lang[language].proportionOfGender}</span>
                                                    {/*}      <div className="columnsOverviewMedium" >Lönekvartil</div>
                                <div className="columnsOverview" >Män</div>
                                <div className="columnsOverview" >Kvinnor</div>
                                <div className="columnsOverviewBig" ></div>

                                <div className="columnsOverviewMedium" >Lägre kvartilen</div>
                                <div className="columnsOverview">75%</div>
                                <div className="columnsOverview" >25%</div>
                                <div className="columnsOverviewBig" > <PercentageBar percentage={75} /></div>
                                        
                                          <div className="columnsOverviewMedium" >Lägre mittkvartilen</div>
                                <div className="columnsOverview">75%</div>
                                <div className="columnsOverview" >25%</div>
        <div className="columnsOverviewBig" > <PercentageBar percentage={75} /></div>*/}

                                                    <div className="percentageBars">
                                                        <div className="columnsOverviewMedium" >{language === "sv" ? "Lägre kvartil" : lang[language].lowerQ}</div>
                                                        {/*<div className="columnsOverview">75% | 25%</div>*/}
                                                        <div className="columnsOverviewBig" > <PercentageBar percentage={this.state.quartiles ? this.state.quartiles.quartile1.menP : 50} /></div>
                                                    </div>
                                                    <div className="percentageBars">
                                                        <div className="columnsOverviewMedium" >{language === "sv" ? "Lägre mittkvartil" : lang[language].lowMidQ}</div>
                                                        <div className="columnsOverviewBig" > <PercentageBar percentage={this.state.quartiles ? this.state.quartiles.quartile2.menP : 50} /></div>
                                                    </div>
                                                    <div className="percentageBars">
                                                        <div className="columnsOverviewMedium" >{language === "sv" ? "Övre mittkvartil" : lang[language].higMidQ}</div>
                                                        <div className="columnsOverviewBig" > <PercentageBar percentage={this.state.quartiles ? this.state.quartiles.quartile3.menP : 50} /></div>
                                                    </div>
                                                    <div>
                                                        <div className="columnsOverviewMedium" >{language === "sv" ? "Övre kvartil" : lang[language].highQ}</div>
                                                        <div className="columnsOverviewBig" > <PercentageBar percentage={this.state.quartiles ? this.state.quartiles.quartile4.menP : 50} /></div>
                                                    </div>


                                                </div>
                                            </div>
                                        </>
                                    }
                                    {/*} <div className="card_overview" style={{ background: "white", color: "#333333", height: 255, width: "45.5%" }}>
                            <div className="card_padding">
                                <span style={{ display: "block", margin: "auto", textAlign: "center", paddingBottom: 5 }}>Andel kön per lönekvartil</span>
                                {this.state.missingEqualGroups ? <div className="card_message">Alla lika arbeten måste grupperas innan denna statistik kan visas. Detta görs i steg 2.1</div> 
                                :
                                    <>
                                        <div style={{ width: "70%", display: "inline-block", marginTop: -20 }}>
                                            <BarChart chartData={data} />
                                        </div>
                                        <div style={{ width: "25%", display: "inline-block", marginTop: 0, verticalAlign: "top" }}>

                                            <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 20 }}>{this.state.maleDominated[1]} %</span>
                                            <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 25 }}>{this.state.womenDominated[1]} %</span>
                                            <span className="card_header gradient_text" style={{ textAlign: "center", marginTop: 25 }}>{this.state.notDominated[1]} %</span>
                                        </div>
                                    </> }
                            </div>
        </div>*/}
                                    {this.state.showWhat !== "original" &&
                                        <>
                                            <div className={this.state.showWhat === "all" ? "card_overview card-overview-white" : "card_overview"} style={{ width: "45.5%", height: 270, background: "white", color: "#333333" }}>
                                                <div className="card_padding">
                                                    <div className="euTitle">{language === "sv" ? "Löneskillnad" : lang[language].payG}</div>
                                                    <div style={{ display: "inline-block", width: "47.5%", marginRight: "5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Årsinkomst</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Medel" : lang[language].average}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalaryWomenOfMen} %</span>
                                                        </div>
                                                        <div style={{ marginTop: 5 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Median" : lang[language].median}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalaryWomenOfMenMedian} %</span>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: "inline-block", width: "47.5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Timlön</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Medel" : lang[language].average}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalaryWomenOfMenHourly} %</span>
                                                        </div>
                                                        <div style={{ marginTop: 5 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Median" : lang[language].median}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalaryWomenOfMenMedianHourly} %</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className={this.state.showWhat === "all" ? "card_overview card-overview-white" : "card_overview"} style={{ width: "45.5%", height: 270, background: "white", color: "#333333" }}>
                                                <div className="card_padding">
                                                    <div className="euTitle">{language === "sv" ? "Tillägg skillnad" : lang[language].benefitDiff}</div>
                                                    <div style={{ display: "inline-block", width: "47.5%", marginRight: "5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Årsinkomst</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Medel" : lang[language].average}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalaryWomenOfMenExtra} %</span>
                                                        </div>
                                                        <div style={{ marginTop: 5 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Median" : lang[language].median}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalaryWomenOfMenMedianExtra} %</span>
                                                        </div>
                                                    </div>

                                                    <div style={{ display: "inline-block", width: "47.5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Timlön</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Medel" : lang[language].average}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalaryWomenOfMenHourlyExtra} %</span>
                                                        </div>
                                                        <div style={{ marginTop: 5 }}>
                                                            <span style={{ display: "inline-block", marginRight: "10%", width: "45%", fontSize: 14.6 }}>{language === "sv" ? "Median" : lang[language].median}</span>
                                                            <span style={{ display: "inline-block", fontSize: 20.6, width: "45%" }} className={this.state.showWhat === "all" ? "card_header card_header-white" : "card_header"}>{this.state.avgSalaryWomenOfMenMedianHourlyExtra} %</span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className={this.state.showWhat === "all" ? "card_overview card-overview-white" : "card_overview"} style={{ width: "45.5%", height: 270, background: "white", color: "#333333" }}>
                                                <div className="card_padding">
                                                    <div className="euTitle">{language === "sv" ? "Innehar tillägg" : lang[language].hasBenefits}</div>
                                                    {/* div>Skillnaden mellan kvinnor och män avseende lönetillägg  rörliga ersättningar</div> */}
                                                    <div style={{ marginRight: "5%", display: "inline-block", width: "47.5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Kvinnor</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <div style={{ width: "50%", display: "inline-block", textAlign: "center" }}>
                                                                <img src={genderWomanBig} style={{ width: 40, marginTop: 20, marginBottom: 11 }} />
                                                            </div>
                                                            <div style={{ width: "50%", display: "inline-block", textAlign: "center", verticalAlign: "top" }}>
                                                                <span style={{ fontWeight: 800, fontSize: 20.6, display: "inline-block", marginTop: 30 }}>{this.state.womenWithExtraPercent} %</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ display: "inline-block", width: "47.5%", textAlign: "center", boxSizing: "border-box", background: "#f7f7f7", borderRadius: 7, padding: 15, marginTop: -5, paddingBottom: 30 }}>
                                                        <div style={{ borderBottom: "1px solid #eaeaea", paddingBottom: 10, color: "grey", fontSize: 14.6 }}>Män</div>
                                                        <div style={{ marginTop: 10 }}>
                                                            <div style={{ width: "50%", display: "inline-block", textAlign: "center" }}>
                                                                <img src={genderManBig} style={{ width: 40, marginTop: 20, marginBottom: 12 }} />
                                                            </div>
                                                            <div style={{ width: "50%", display: "inline-block", textAlign: "center", verticalAlign: "top" }}>
                                                                <span style={{ fontWeight: 800, fontSize: 20.6, display: "inline-block", marginTop: 30 }}>{this.state.menWithExtraPercent} %</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {(this.state.showWhat !== "original" && this.state.showWhat !== "intern") &&
                                        <>
                                            {/* <div style={{ marginTop: 40, cursor: "pointer" }} className="euTitle" onClick={this.toggleWorkCategories}>{language === "sv" ? "Visa löneskillnad per arbetstagarkategori" : lang[language].payGapCategory}<img style={{ marginLeft: 5, verticalAlign: "middle" }} src={this.state.showWorkCategories ? up : down} width={15} /></div>*/}

                                            {/* <div className={this.state.showWorkCategories ? "reportingListContainer" : "hide"}>*/}
                                            <div className="reportingListContainer">
                                                <div className="euTitle">Löneskillnad per arbetstagarkategori</div>
                                                <div style={{ marginTop: -20, marginBottom: 20, color: "grey", fontSize: 13 }}
                                                >Nedan är hämtat från lönekartläggningen</div>
                                                <div style={{ margin: "auto", color: "#333333", width: "45.5%", textAlign: "center", boxShadow: "none", paddingBottom: 40, height: "auto", minWidth: 450, background: "#f7f7f7", borderRadius: 7, padding: 20 }}>
                                                    {workCategoriesPayGap}
                                                </div>
                                                {/* <div style={{ paddingBottom: 5, fontSize: 12, fontStyle: "italic", marginBottom: 20 }}>{language === "sv" ? "Ovan visar löneskillnad för arbeten med likvärdiga krav. Detaljerad analys kan göras i systemet." : lang[language].detailedAnaInfo}</div> */}
                                            </div>
                                        </>
                                    }
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
}


