import React from 'react';
import './App.css';
import { Import } from './components/Importera/Import';
import { Overview } from './components/Overview/Overview';
import { GrupperaLika } from './components/GrupperaLika/GrupperaLika';
import { Arbetsvardera } from './components/Arbetsvardera/Arbetsvardera';
import { Lika } from './components/AnalysLika/Lika';
import { Likvardiga } from './components/AnalysLikvardiga/Likvardiga';
import { Dokumentera } from './components/Dokumentera/Dokumentera';
import { Login } from './components/Login/Login';
import { Utvardera } from './components/Utvardera/Utvardera';
import { Introduktion } from './components/Introduktion/Introduktion';
import { Admin } from './components/KoncernVy/Admin';
import { KoncernVy } from './components/KoncernVy/Koncernvy';
import { KonsultVy } from './components/KoncernVy/KonsultVy';
import { TwoFactor } from './components/Login/twoFactor';
import { BolagsVy } from './components/KoncernVy/BolagsVy';
import  Settings  from './components/moreSettings/Settings';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { fetchAuth } from './fetch-auth';
import { Arkitektur } from './components/Arkitektur/Arkitektur';
import { Foraldraledighet } from './components/Foraldraledighet/Foraldraledighet';
import { Reporting } from './components/Rapportering/Reporting';
/*if (process.env.NODE_ENV === "development") {console.log("its dev!")}
if (process.env.NODE_ENV === "production") {console.log("its prod!")}*/
const auth = {
  isAuthenticated: true
}

function PrivateRoute({ children, ...rest }) {
  if (localStorage.getItem('localT') == null || undefined) { //If no local token
    auth.isAuthenticated = false
  }
  else {
    const localT = localStorage.getItem('localT');
    const { exp } = jwt_decode(localT);
    if (Date.now() <= exp * 1000) { //valid
      auth.isAuthenticated = true
    } else {
      auth.isAuthenticated = false
    }
  }
  return (
    <Route {...rest} render={() => {
      return auth.isAuthenticated === true ? children : <Redirect to='/login' />
    }} />
  )
}

function AdminRoute({ children, ...rest }) {
  if (localStorage.getItem('localT') == null || undefined) { //If no local token
    auth.isAuthenticated = false
  }
  else {
    const localT = localStorage.getItem('localT');
    const { exp, role } = jwt_decode(localT);
    //  console.log(exp)
    if (Date.now() <= exp * 1000 && role === "Admin") { //both are validvalid
      auth.isAuthenticated = true
    } else {
      auth.isAuthenticated = false
    }
  }
  return (
    <Route {...rest} render={() => {
      return auth.isAuthenticated === true ? children : <Redirect to='/login' />
    }} />
  )
}

function KoncernRoute({ children, ...rest }) {
  if (localStorage.getItem('localT') == null || undefined) { //If no local token
    auth.isAuthenticated = false
  }
  else {
    const localT = localStorage.getItem('localT');
    const { exp, role } = jwt_decode(localT);
    // console.log(exp)
    if (Date.now() <= exp * 1000 && role === "Koncern") { //both are validvalid
      auth.isAuthenticated = true
    } else {
      auth.isAuthenticated = false
    }
  }
  return (
    <Route {...rest} render={() => {
      return auth.isAuthenticated === true ? children : <Redirect to='/login' />
    }} />
  )
}

function KonsultRoute({ children, ...rest }) {
  if (localStorage.getItem('localT') == null || undefined) {
    //  console.log('no localToken!')
    auth.isAuthenticated = false
  }
  else {
    const localT = localStorage.getItem('localT');
    const { exp, role } = jwt_decode(localT);
    //   console.log(exp)
    if (Date.now() <= exp * 1000 && role === "Konsult") {
      //   console.log('Valid date and correct role (Konsult)')
      auth.isAuthenticated = true
    } else {
      auth.isAuthenticated = false
    }
  }
  return (
    <Route {...rest} render={() => {
      return auth.isAuthenticated === true ? children : <Redirect to='/login' />
    }} />
  )
}

function BolagsRoute({ children, ...rest }) {
  if (localStorage.getItem('localT') == null || undefined) {
    //    console.log('no localToken!')
    auth.isAuthenticated = false
  }
  else {
    const localT = localStorage.getItem('localT');
    const { exp, role } = jwt_decode(localT);
    //  console.log(exp)
    if (Date.now() <= exp * 1000 && role === "Bolag") {
      //    console.log('Valid date and correct role (Konsult)')
      auth.isAuthenticated = true
    } else {
      auth.isAuthenticated = false
    }
  }
  return (
    <Route {...rest} render={() => {
      return auth.isAuthenticated === true ? children : <Redirect to='/login' />
    }} />
  )
}

function LogRoute({ children, ...rest }) {
  // console.log(auth.logStatus)
  return (
    <Route {...rest} render={() => {
      return auth.isAuthenticated === false ? children : <Redirect to='/' />
    }} />
  )
}

class App extends React.Component {
  // ComponentDidMount: Clear cache to make sure user gets the newest version. This update check runs once a day (not hours) and only when we refresh the page.
  componentDidMount() {
    const getData = async () => {
      try {
        // Get todays date and last checked date
        const today = new Date().toDateString() //Todays date
        let checkedUpdate = localStorage.getItem('checkedUpdate')
        // If last check is today, do nothing
        if (today == checkedUpdate) {
          return; 
        }
        // Set localStorage to today if there is none
        if (checkedUpdate == null || checkedUpdate == "null" || checkedUpdate == undefined) {
          checkedUpdate = today
          localStorage.setItem("checkedUpdate", today); 
        }
        // Check for update
        localStorage.setItem("checkedUpdate", today); 
        const version = "7.6"
        const response = await fetchAuth(`/api/checkUpdate`, 'POST', JSON.stringify({ version: version }));
        let data = await response.json();
        //If there is an update, clear cache
        if (data === "Update") {
          if ('caches' in window) {
            caches.keys().then((names) => {
           // Delete all the cache files
           names.forEach(name => {
               caches.delete(name);
           })
          });
          console.log("Cache emptied")
          window.location.reload(true); //changes only visible after reload
        }
          return; 
        } 
        // If no update available, then just update last checked time to today
        else {
          localStorage.setItem("checkedUpdate", today);
          console.log("Checked update, no update needed.")
          return; 
        }
      } catch (err) {
        console.log("An error occured when trying to update.")
      }
    }
    getData();
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/twoFactor" ><TwoFactor /></Route >
            <PrivateRoute exact path="/settings"> <Settings /> </PrivateRoute>
            <PrivateRoute exact path="/import"> <Import /> </PrivateRoute>
            <PrivateRoute exact path="/overview"> <Overview /> </PrivateRoute>
            <PrivateRoute exact path="/"> <Introduktion /> </PrivateRoute>
            <PrivateRoute exact path="/gruppera"><GrupperaLika /> </PrivateRoute> {/* Must look like this (look right) --> to work with redirect, se login komponent:  this.props.history.push('/'): <Route path="/gruppera" exact component={GrupperaLika} />*/}
            <PrivateRoute exact path="/gruppera/arbetsvardera"> <Arbetsvardera /></PrivateRoute>
            {/*<Route path="/" exact component={Import}/> */}<PrivateRoute exact path="/"> <Import /></PrivateRoute>
            <PrivateRoute exact path="/analyslika"> <Lika /></PrivateRoute>
            <PrivateRoute exact path="/analyslika/analyslikvardiga"> <Likvardiga /></PrivateRoute>
            <PrivateRoute exact path="/analyslika/foraldraledighet"> <Foraldraledighet /></PrivateRoute>
            <PrivateRoute exact path="/rapportering"> <Reporting /></PrivateRoute>
            <PrivateRoute exact path="/analyslika/utvardera"> <Utvardera /></PrivateRoute>
          {/*  <PrivateRoute exact path="/arkitektur"> <Arkitektur /></PrivateRoute> */}
            <PrivateRoute exact path="/dokumentera"> <Dokumentera /></PrivateRoute>
            <KoncernRoute exact path="/koncernVy"> <KoncernVy /></KoncernRoute>
            <KonsultRoute exact path="/konsultVy"> <KonsultVy /></KonsultRoute>
            <BolagsRoute exact path="/bolagsVy"> <BolagsVy /></BolagsRoute>
            <LogRoute exact path="/login"> <Login /></LogRoute>
            <AdminRoute exact path="/admin"><Admin /></AdminRoute>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;

